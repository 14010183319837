import { type WalletClient } from 'viem';
import { eip712WalletActions, getGeneralPaymasterInput } from 'viem/zksync';
import { sophonMainnet, sophonTestnet } from './customChains';

const zkSyncChains: number[] = [sophonTestnet.id, sophonMainnet.id];

const zkSyncPaymasterAddresses: Record<number, `0x${string}`> = {
  [sophonTestnet.id]: '0x950e3Bb8C6bab20b56a70550EC037E22032A413e',
  // SOPHON_UPDATE_ME: add  real mainnet paymaster address
  [sophonMainnet.id]: '0x0000000000000000000000000000000000000b0b',
};

const isZkSyncChain = (chainId: number): boolean => zkSyncChains.includes(chainId);

// adds additional zkSync mods to the wallet if required.
// should be called for *all* zkSync chains, can be called safely for any chain.
export const setupZkSyncWallet = (wallet: WalletClient, chainId: number): WalletClient => {
  if (isZkSyncChain(chainId)) {
    // add viem's zkSync mods to the wallet
    return wallet.extend(eip712WalletActions());
  }

  return wallet;
};

// returns zkSync paymaster data to attach to tx calls.
export const getPaymasterData = (
  chainId: number,
  paymasterInnerInput: `0x${string}` | Uint8Array = '0x',
): Record<string, any> => {
  const paymaster = zkSyncPaymasterAddresses[chainId];

  if (paymaster) {
    return {
      paymaster,
      paymasterInput: getGeneralPaymasterInput({
        innerInput: paymasterInnerInput,
      }),
    };
  }

  return {};
};
