import { env } from '@/env.mjs';
import { BeamConfiguration, ChainId } from '@onbeam/sdk';

const environment = env.NEXT_PUBLIC_BEAM_ENVIRONMENT;
const publishableKey = `${env.NEXT_PUBLIC_BEAM_PUBLISHABLE_KEY}`;

const config = new BeamConfiguration({
  chains: [
    {
      id:
        env.NEXT_PUBLIC_BEAM_ENVIRONMENT === 'mainnet'
          ? ChainId.BEAM_MAINNET
          : ChainId.BEAM_TESTNET,
      publishableKey,
      isPreview: environment === 'preview',
      sponsor: false, // adjust to 'true' when Openfort added their wildcard policy rule solution for sponsoring all contracts / https://meritcircle.slack.com/archives/C054AQY5SQ5/p1727181381710069?thread_ts=1727181066.535319&cid=C054AQY5SQ5
    },
  ],
  debug: environment === 'preview',
});

export { config };
